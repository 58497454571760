import React from 'react'
import { Box } from '@chakra-ui/layout'
import { get } from 'lodash'
import { Text } from '@chakra-ui/react'

const PoiInfo = ({ user }) => {
  return (
    <Box>
      <Text style={{ fontSize: '20px' }} pt={'46px'} isTruncated>
        {get(user, 'poiName')}
      </Text>
      <Text isTruncated>
        {`${get(user, 'address', '')}, ${get(user, 'postalCode', '')} ${get(
          user,
          'city',
          ''
        )},${get(user, 'state', '')} ${get(user, 'country', '')}`}
      </Text>
    </Box>
  )
}

export default PoiInfo
