import axios from 'axios'
import { get } from 'lodash'

const checkToken = async ({ token }) => {
  console.log('[checkToken] token', token)
  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/checkToken`, { token })
    return [result?.data || {}]
  } catch (e) {
    return [undefined, e]
  }
}

const getAssets = async (targetId) => {
  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/siteCore/search`, {
      targetId,
    })
    return [get(result, 'data') || {}]
  } catch (e) {
    return [undefined, e]
  }
}

const deleteAsset = async (id, copyright, otherProvider, poiName) => {
  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/siteCore/delete`, {
      id,
      copyright,
      otherProvider,
      poiName,
    })
    return [get(result, 'data') || {}]
  } catch (e) {
    return [undefined, e]
  }
}

// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })

const upload = async ({ file, userId, metadata }, onUploadProgress) => {
  const {
    // title,
    subject,
    quality,
    relateddInspecId,
    author,
    provider,
    assetId,
    otherProvider,
  } = metadata
  const formData = new FormData()
  // if (file.size > LIMIT) {
  //   const params = {
  //     Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
  //     Key: `uploads/${process.env.REACT_APP_STAGE}/${relateddInspecId}/${get(file, 'name')}`,
  //     Body: file
  //   }
  //   const resp = await S3.putObject(params).on('httpUploadProgress', onUploadProgress).promise()
  //   console.log('stored', resp.data, resp.error, resp.body, resp.statusCode)

  //   formData.append('fileKey', get(params, 'Key'))
  // } else {
  formData.append('file', file)
  console.log('file', file.size)
  // let base64
  // if (file) {
  //   base64 = await toBase64(file)
  //   formData.append('fileBase64', base64)
  // }
  //formData.append('base64', new Buffer(file.content, 'base64'))
  // }

  formData.append('filename', get(file, 'name'))

  formData.append('contentType', get(file, 'type'))

  formData.append('userId', userId)
  // formData.append('title', title)
  formData.append('subject', subject)
  formData.append('quality', quality)
  formData.append('relateddInspecId', relateddInspecId)
  formData.append('author', author)
  formData.append('provider', provider)
  formData.append('assetId', assetId)
  formData.append('otherProvider', otherProvider)

  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    console.log('upload', result)
    return [result]
  } catch (e) {
    console.log('api.upload'.e)
    console.log('upload error', e.message)
    return [undefined, e]
  }
}
const DEFAULT_LANGUAGE = 'en-US'

const getSubjectOptionsList = async (language) => {
  let options = []

  try {
    const result = await axios.get(
      `/${process.env.REACT_APP_STAGE}/v1/siteCore/getSubjectOptionsList`
    )
    if (result && result.data) {
      options = result.data.map((e) => ({
        identifier: e.identifier,
        label: e.labels[language] || e.labels[DEFAULT_LANGUAGE],
      }))
    }
    return [options]
  } catch (e) {
    return [undefined, e]
  }
}
const getQualityOptionsList = async (language) => {
  let options = []

  try {
    const result = await axios.get(
      `/${process.env.REACT_APP_STAGE}/v1/siteCore/getQualityOptionsList`
    )
    if (result && result.data) {
      options = result.data.map((e) => ({
        identifier: e.identifier,
        label: e.labels[language] || e.labels[DEFAULT_LANGUAGE],
      }))
    }
    return [options]
  } catch (e) {
    return [undefined, e]
  }
}

const test = async (p) => {
  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/test`, p)
    return [result?.data || {}]
  } catch (e) {
    return [undefined, e]
  }
}

const getPendingFiles = async (userId) => {
  try {
    const result = await axios.get(`/${process.env.REACT_APP_STAGE}/v1/getPendingFiles/${userId}`)
    return [result?.data || {}]
  } catch (e) {
    return [undefined, e]
  }
}

const validate = async (params) => {
  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/process`, params)
    return [result?.data || {}]
  } catch (e) {
    return [undefined, e]
  }
}

const changeLanguage = async (newLanguage, userId) => {
  console.log('[changeLanguage] newLanguage', newLanguage)
  console.log('[changeLanguage] userId', userId)

  try {
    const result = await axios.post(`/${process.env.REACT_APP_STAGE}/v1/changeLanguage`, {
      newLanguage,
      userId,
    })
    return [result?.data || {}]
  } catch (e) {
    return [undefined, e]
  }
}

export {
  checkToken,
  upload,
  getSubjectOptionsList,
  getQualityOptionsList,
  getAssets,
  test,
  deleteAsset,
  validate,
  getPendingFiles,
  changeLanguage,
}
