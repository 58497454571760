import React from 'react'
import { Flex } from '@chakra-ui/layout'
import { ButtonTab } from '@components/Base'
import { useTranslation } from 'react-i18next'

const ChangeLanguage = (props) => {
  const { isActive = false, languages, user, changeLanguageHandler = () => {} } = props

  const { i18n } = useTranslation()

  const transformLanguageCode = (languageCode) => {
    return languageCode
      .split('_')
      .map((part, index) => (index === 1 ? part.toUpperCase() : part))
      .join('-')
  }

  const resultTransformLanguageCode = transformLanguageCode(i18n.language)

  return isActive ? (
    <Flex
      flexDirection="row"
      justifyContent="flex-end"
      gap="2"
      borderBottom="1px solid"
      borderBottomColor="gray.200">
      {languages.map((language) => (
        <ButtonTab
          key={language}
          size={{ base: 'md', md: 'sm' }}
          isActive={resultTransformLanguageCode === language.code}
          onClick={() => {
            changeLanguageHandler(user, language.code)
          }}>
          {language.label}
        </ButtonTab>
      ))}
    </Flex>
  ) : null
}

export default ChangeLanguage
