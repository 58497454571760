import React, { useState, useRef } from 'react'
import {
  Flex,
  Collapse,
  useOutsideClick,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { Spacer } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/layout'
import { ImageCard } from '@components/Cards'
import Title from '@components/Title'
import HtmlContainer from '@components/HtmlContainer'
import { ButtonPrimary } from '@components/Base'
import { useTranslation } from 'react-i18next'
import ImageForm from './ImageForm'
import UserForm from './UserForm'
import { isEmpty, delay } from 'lodash'

const UploadFiles = (props) => {
  const { data, user, isOpen = false, onClose = () => {}, reloadHandler = () => {} } = props
  const ref = useRef()

  useOutsideClick({
    ref: ref,
    handler: () => {}, // close()
  })

  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [error, setError] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)

  return (
    <>
      {isCompleted && !isEmpty(uploadedFiles) ? (
        <>
          <Box color="gray.500" fontSize={'14px'} mb={10}>
            <HtmlContainer
              html={error ? t('save-error-description') : t('save-success-description')}
            />
          </Box>

          <Wrap>
            {uploadedFiles.map((e) => (
              <WrapItem key={e.id} w={['100%', '270px']}>
                <ImageCard
                  withActions={false}
                  id={e.id}
                  title={e.title}
                  image={e.image}
                  subject={e.subject}
                  otherProvider={e.otherProvider}
                />
              </WrapItem>
            ))}
          </Wrap>
          <Flex direction={['column', 'row']} mb={4}>
            <Spacer />
            <ButtonPrimary
              mt={4}
              w={['100%', '190px']}
              reverse={false}
              onClick={() => {
                onClose()
                setUploadedFiles([])
                setIsCompleted(false)
                delay(reloadHandler, 3000)
              }}>
              {t('ok')}
            </ButtonPrimary>
          </Flex>
        </>
      ) : (
        <>
          <Collapse in={isOpen} animateOpacity ref={ref}>
            <Title text={step === 0 ? t('add-image-title') : t('finalize-title')} />
            <>
              <Tabs index={step} isLazy colorScheme="red">
                <TabList mb="1em" borderColor="gray.300" color="gray.300">
                  <Tab>{t('add-image-tab')}</Tab>
                  <Tab isDisabled={step === 0}>{t('validate-tab')}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <ImageForm
                      {...{
                        data,
                        user,
                        onClose: () => {
                          onClose()
                          setUploadedFiles([])
                        },
                        setStep,
                        setFiles: setUploadedFiles,
                      }}
                    />
                  </TabPanel>

                  <TabPanel>
                    <UserForm
                      {...{
                        data,
                        user,
                        onClose: () => {
                          onClose()
                          setUploadedFiles([])
                        },
                        setStep,
                        setError,
                        setIsCompleted,
                        uploadedFiles,
                      }}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          </Collapse>
        </>
      )}
    </>
  )
}

export default UploadFiles
