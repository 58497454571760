import React, { useEffect, useState, useRef } from 'react'
import {
  Flex,
  Grid,
  Box,
  FormControl,
  FormLabel,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Spacer } from '@chakra-ui/layout'
import HtmlContainer from '@components/HtmlContainer'
import { ButtonPrimary, Input, Checkbox } from '@components/Base'

import { isEmpty, get, lowerCase } from 'lodash'
import * as API from '@api'
import { useTranslation } from 'react-i18next'
const UserForm = ({ data, user, onClose, setStep, setIsCompleted, uploadedFiles, setError }) => {
  const { isOpen: isOpenCGU, onOpen: openCGU, onClose: closeCGU } = useDisclosure()

  const { t } = useTranslation()
  const [saving, setSaving] = useState(false)
  const [isValidateDisabled, setIsValidateDisabled] = useState(false)
  const [country, setCountry] = useState(get(user, 'country'))
  const [uploaderName, setUploaderName] = useState('')
  const [uploaderPosition, setUploaderPosition] = useState('')
  const [uploaderEmail, setUploaderEmail] = useState(get(user, 'email', ''))
  const [CGUValidation, setCGUValidation] = useState(false)
  const [rightDisclaimer, setRightDisclaimer] = useState(false)
  const [CGURead, setCGURead] = useState(false)
  const [sharePrivateData, setSharePrivateData] = useState(false)

  const isKorea = (country) =>
    country && (lowerCase(country) === 'ko' || lowerCase(country).indexOf('korea') !== -1)

  const isMalaysia = (country) =>
    country && (lowerCase(country) === 'ma' || lowerCase(country).indexOf('malaysia') !== -1)

  const isSingapore = (country) =>
    country && (lowerCase(country) === 'sg' || lowerCase(country).indexOf('singapore') !== -1)

  const isVietnam = (country) =>
    country && (lowerCase(country) === 'vi' || lowerCase(country).indexOf('viet nam') !== -1)

  const isSharePrivateData = (country) =>
    isKorea(country) || isMalaysia(country) || isSingapore(country) || isVietnam(country)

  useEffect(() => {
    setIsValidateDisabled(
      isEmpty(uploaderName) ||
        isEmpty(uploaderPosition) ||
        isEmpty(uploaderEmail) ||
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          uploaderEmail
        ) ||
        !CGUValidation ||
        (isKorea(country) && !CGURead) ||
        !rightDisclaimer ||
        (isSharePrivateData(country) && !sharePrivateData)
    )
  }, [
    uploaderName,
    uploaderPosition,
    uploaderEmail,
    CGUValidation,
    rightDisclaimer,
    CGURead,
    sharePrivateData,
  ])

  const reset = () => {
    setStep(0)
    setUploaderName('')
    setUploaderPosition('')
    setUploaderEmail(get(user, 'email'))
    setCGUValidation(false)
    setRightDisclaimer(false)
    setCGURead(false)
    setSharePrivateData(false)
  }

  const close = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (data) {
      setUploaderName(get(data, 'uploaderName') || '')
      setUploaderPosition(get(data, 'uploaderPosition') || '')
    }
    if (user) {
      setUploaderEmail(get(user, 'email', '') || '')
      setCountry(get(user, 'country'))
    }
  }, [data, user])

  const save = async () => {
    setSaving(true)
    const [response, err] = await API.validate({
      files: uploadedFiles.map((p) => get(p, 'id')),
      userId: user.Id,
      language: get(user, 'spokenLanguages[0]', 'en'),
      country,
      uploaderName,
      uploaderPosition,
      uploaderEmail,
      CGUValidationDate: CGUValidation ? new Date() : null,
      rightDisclaimerDate: rightDisclaimer ? new Date() : null,
      CGUReadDate: CGURead ? new Date() : null,
      city: get(user, 'city'),
      poiName: get(user, 'poiName'),
    })
    if (err) {
      // console.log('[UserForm.save]', err)
      setError(true)
    }
    if (response) {
      //close()
      setError(false)
    }
    setIsCompleted(true)
    reset()
    setSaving(false)
  }

  return (
    <>
      <Flex direction={['column', 'row']}>
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} w="100%">
          <FormControl isRequired>
            <FormLabel>{t('upload-form-uploader-name')}</FormLabel>
            <Input
              placeholder={t('upload-form-uploader-name')}
              value={uploaderName}
              onChange={(e) => setUploaderName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t('upload-form-uploader-position')}</FormLabel>
            <Input
              placeholder={t('upload-form-uploader-position')}
              value={uploaderPosition}
              onChange={(e) => setUploaderPosition(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t('upload-form-email')}</FormLabel>
            <Input
              type="email"
              placeholder={t('upload-form-email')}
              value={uploaderEmail}
              onChange={(e) => setUploaderEmail(e.target.value)}
            />
          </FormControl>
          <Spacer />
        </Grid>
      </Flex>
      <VStack spacing={2} direction="row" mt={4} alignItems="flex-start">
        {isSharePrivateData(country) && (
          <Checkbox
            isRequired={true}
            borderColor="blackAlpha.500"
            value={sharePrivateData}
            colorScheme="red"
            onChange={(e) => setSharePrivateData(e.target.checked)}>
            {t('upload-form-share-private-data')}{' '}
            <Text as="span" color="red">
              {'*'}
            </Text>
          </Checkbox>
        )}
        <Checkbox
          isRequired
          isChecked={CGUValidation}
          borderColor="blackAlpha.500"
          value={CGUValidation}
          colorScheme="red"
          onChange={() => {
            openCGU()
            //setCGUValidation(e.target.checked)
          }}>
          {t('upload-form-cgu-validation')}
          <Text as="span" color="red">
            {'*'}
          </Text>
        </Checkbox>
        {isKorea(country) && (
          <Checkbox
            isRequired={true}
            borderColor="blackAlpha.500"
            value={CGURead}
            colorScheme="red"
            onChange={(e) => setCGURead(e.target.checked)}>
            {t('upload-form-cgu-read')}{' '}
            <Text as="span" color="red">
              {'*'}
            </Text>
          </Checkbox>
        )}
        <Checkbox
          borderColor="blackAlpha.500"
          value={rightDisclaimer}
          colorScheme="red"
          onChange={(e) => setRightDisclaimer(e.target.checked)}>
          {t('upload-form-right-disclaimer')}{' '}
          <Text as="span" color="red">
            {'*'}
          </Text>
        </Checkbox>
        <Flex pt="20px">
          <HtmlContainer html={t('mandatory-fields')} />
        </Flex>
      </VStack>
      <Flex direction={['column', 'row']} mb={4}>
        <Spacer />
        <ButtonPrimary
          mt={4}
          w={['100%', '190px']}
          mr={[0, 3]}
          onClick={close}
          isLoading={saving}
          reverse={true}>
          {t('cancel-all')}
        </ButtonPrimary>
        <ButtonPrimary
          mt={4}
          w={['100%', '190px']}
          reverse={false}
          onClick={save}
          isLoading={saving}
          isDisabled={isValidateDisabled}
          loadingText={t('validate-loading-text')}>
          {t('validate')}
        </ButtonPrimary>
      </Flex>
      <ModalCGU
        isOpen={isOpenCGU}
        onClose={() => {
          setCGUValidation(false)
          closeCGU()
        }}
        save={() => {
          setCGUValidation(true)
          closeCGU()
        }}
      />
    </>
  )
}

const ModalCGU = ({ isOpen, onClose, save }) => {
  const btnRef = useRef()
  const { t } = useTranslation()
  const [html, setHtml] = useState('')

  useEffect(() => {
    try {
      import(`../../i18n/contents/${t('terms-of-use-html-content')}`).then((__html) =>
        setHtml(__html.default)
      )
    } catch (error) {
      // console.log('[ModalCGU]', error)
    }
  }, [html])

  return (
    <Modal
      onClose={onClose}
      finalFocusRef={btnRef}
      isOpen={isOpen}
      scrollBehavior="inside"
      size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box overflowY={'scroll'} my="20px" h={'300px'} overflowX={'hidden'}>
            {!isEmpty(html) && <HtmlContainer html={html} />}
            <Flex>
              <ButtonPrimary m={4} w={['100%', '190px']} reverse={true} onClick={onClose}>
                {t('refuse')}
              </ButtonPrimary>
              <ButtonPrimary m={4} w={['100%', '190px']} onClick={save}>
                {t('accept')}
              </ButtonPrimary>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default UserForm
